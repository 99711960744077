import styled from "styled-components";

export const StyledBeekeeperInfo = styled.div`
  display: flex;
  margin: 20px;
`;

export const StyledBeekeeperEmail = styled.div`
  width: 30%;
`;

export const StyledBeekeeperContainer = styled.div`
  width: 100%;
`;

export const StyledBeekeeperHoney = styled.div`
  width: 30%;
`;
